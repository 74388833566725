// Here you can add other styles

body {
    background-color: rgb(99, 111, 131);
    text-align: center;
    .table__header {
        thead {
            text-align: left
        }
    }
    .servers__table {
        min-height: 155px;
    }
    .close {
        font-size: 2rem;
    }
    .configurator__modal {
        background-color: rgb(47, 53, 58);
    }
    .configurator__background {
        overflow-x: auto;
        flex-shrink: 0;
        height: 800px;
        &::-webkit-scrollbar{
            height: 5px;
        }
        &::-webkit-scrollbar-track {
            border-radius: 4px;
        }
        &::-webkit-scrollbar-thumb {
            border-radius: 4px;
            background: rgb(47, 53, 58);
        }
        &:hover::-webkit-scrollbar-thumb {
            background: rgb(90, 95, 100);;
        }
        .configurator__main__window {
            max-width: 100%;
            .nav-tabs {
                flex-wrap: nowrap;
                overflow-x: hidden;
                overflow-y: hidden;
                &:hover {
                  overflow-x: auto;  
                }
                &::-webkit-scrollbar{
                    height: 5px;
                }
                &::-webkit-scrollbar-track {
                    border-radius: 4px;
                }
                &::-webkit-scrollbar-thumb {
                    border-radius: 4px;
                    background: rgb(47, 53, 58);
                }
                &:hover::-webkit-scrollbar-thumb {
                    background: rgb(90, 95, 100);;
                }
                .nav-item {
                    flex-shrink: 0;
                }
                @media(max-width: 1305px) {
                    overflow-x: auto;
                }
            }
            .configurator__main__window__first {
                width: 100%;
                flex-basis: 50%;
                border-right: 1px solid white;
            }
            .configurator__main__window__second {
                width: 100%;
                flex-basis: 50%;
            }
            .nav-item a {
                background-color: transparent;
                color: rgb(144, 148, 151);
            }
            .tab__close__button {
                opacity: 0;
                &:hover {
                    opacity: 1;
                }
            }
            .active__tab {
                opacity: 1;
            }
        }
        background-color: rgb(47, 53, 58);
        .search__tab__background {
            background-color: rgb(35, 38, 41);
            .search__input__column {
                .text {
                    font-size: 13px;
                    text-align: left;
                }
                .MuiTreeItem-content {
                    color: rgb(144, 148, 151);
                }
                .col {
                    padding: 0
                }
            }
            .search__input {
                background-color: rgb(47, 53, 58);
                border: 0
            }
            .header__text {
                font-size: 10px;
                color: rgb(144, 148, 151);
            }
        }
        .c-sidebar {
            background-color: rgb(47, 53, 58);
            flex-basis: 50px;
            border-top-left-radius: 0.25rem;
            border-bottom-left-radius: 0.25rem;
            html:not([dir="rtl"]) .c-sidebar:not(.c-sidebar-right) {
                margin-left: 0px; 
            }
        }
        .sidebar__content__window {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            background-color: rgb(35, 38, 41);
            flex-basis: 230px;
            @media(max-width: 1200px) {
                flex-basis: 200px;
            }
            @media(max-width: 900px) {
                flex-basis: 180px;
            }
            @media(max-width: 600px) {
                flex-basis: 160px;
            }
            @media(max-width: 400px) {
                flex-basis: 140px;
            }
        }
        .configurator__sidebar__link {
            .nav-item {
                &:hover {
                    border: 0;
                    background-color: rgb(68, 76, 83);
                }
            }
            .nav-link.active {
                background-color: rgb(35, 38, 41);
                border: 0;
            }
            a {
                color: white;
            }
        }
        @media (max-width: 991.98px) {
            .c-sidebar {
                --is-mobile: false;
                position: static;
                z-index: 0
            }
        }     
    }
    .domain__add {
        background-color: rgb(235, 240, 245);
        .form-group {
            margin-bottom: 0
        }
    }
    .page-item.active .page-link {
        background-color: rgb(248, 108, 107);
        border-color: rgb(248, 108, 107)
    }
    .layout {
        width: 50%
    }
    @media(max-width: 1200px) {
        .layout {
            width: 75%
        }
    }
    @media(max-width: 600px) {
        .layout {
            width: 100%
        }
    }
}
.modal-registration {
    .modal-lg {
        max-width: 400px
    }
}
.c-sidebar .c-sidebar-nav-link {
    &:hover {
        background-color: rgb(51, 65, 90);
    }
}
html:not([dir="rtl"]) .c-sidebar:not(.c-sidebar-right) {
    margin-left: 0px; 
}